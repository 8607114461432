import CloseIcon from "@mui/icons-material/Close";
import { Grid2, IconButton, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useMemo } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { Icons } from "../../utility/icons";

import { BulkPrintDialogListItem_QueryEquipmentListItemResult$key } from "./__generated__/BulkPrintDialogListItem_QueryEquipmentListItemResult.graphql";
import { BulkPrintDialogListItem_QuerySparePartsItemResult$key } from "./__generated__/BulkPrintDialogListItem_QuerySparePartsItemResult.graphql";

// The component needs to be able to display either equipment or spare part data, so we use a discriminated union to make sure
// that either equipment or sparePart is provided, but not both.
type BulkPrintDialogListItemProps = { lineNumber: number; onRemove: (id: string) => void } & (
  | {
      equipment: BulkPrintDialogListItem_QueryEquipmentListItemResult$key;
      sparePart?: undefined;
    }
  | { equipment?: undefined; sparePart: BulkPrintDialogListItem_QuerySparePartsItemResult$key }
);

export const BulkPrintDialogListItem: FC<BulkPrintDialogListItemProps> = (props) => {
  const { lineNumber, onRemove, equipment: equipmentRef, sparePart: sparePartRef } = props;
  const { palette } = useTheme();
  const { translate } = useTranslation();

  const equipment = useFragment(
    graphql`
      fragment BulkPrintDialogListItem_QueryEquipmentListItemResult on QueryEquipmentListItemResult {
        __typename
        ... on NotFoundError {
          requestedId
        }
        ... on QueryEquipmentListItemSuccess {
          data {
            equipmentNumber
            description
          }
        }
      }
    `,
    equipmentRef ?? null,
  );

  const sparePart = useFragment(
    graphql`
      fragment BulkPrintDialogListItem_QuerySparePartsItemResult on QuerySparePartsItemResult {
        __typename
        ... on NotFoundError {
          requestedId
        }
        ... on SparePart {
          sparePartNumber
          description
        }
      }
    `,
    sparePartRef ?? null,
  );

  const data = equipment ?? sparePart;

  const displayId = useMemo(() => {
    switch (data?.__typename) {
      case "QueryEquipmentListItemSuccess":
        return data.data.equipmentNumber.toString();
      case "SparePart":
        return data.sparePartNumber;
      case "NotFoundError":
        return data.requestedId;
      default:
        return ""; // This should only happen if the fragment is empty in which case we don't display anything.
    }
  }, [data]);

  const description = useMemo(() => {
    switch (data?.__typename) {
      case "QueryEquipmentListItemSuccess":
        return data.data.description;
      case "SparePart":
        return data.description;
      default:
        return translate("BULK_PRINT.ERRORS.INVALID_ID", "Invalid ID");
    }
  }, [data, translate]);

  const icon = useMemo(() => {
    switch (data?.__typename) {
      case "QueryEquipmentListItemSuccess":
        return <Icons.EquipmentId style={{ fontSize: 36 }} />;
      case "SparePart":
        return <Icons.SparePartCogs style={{ fontSize: 36 }} />;
      default:
        return <Icons.WarningTriangle style={{ fontSize: 36 }} />;
    }
  }, [data]);

  return (
    <Grid2
      container
      direction="row"
      spacing={4}
      px={2}
      color={data?.__typename === "NotFoundError" ? palette.error.main : undefined}
      // Patch for stupid magic white space issues
      maxHeight={40}
    >
      <Grid2 container direction="row" size={{ xs: 12 }}>
        <Grid2 container alignItems="center" size={{ xs: 1 }}>
          <Typography variant="h3">{lineNumber}</Typography>
        </Grid2>
        <Grid2 container alignItems="center" size={{ xs: 1 }}>
          {icon}
        </Grid2>
        <Grid2 container alignItems="center" size={{ xs: 2 }}>
          <Typography variant="h5">{displayId}</Typography>
        </Grid2>
        <Grid2 container alignItems="center" size={{ xs: 7 }}>
          <Typography
            variant="h5"
            // Truncate the description if it's too long
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </Typography>
        </Grid2>
        <Grid2 container alignItems="center" size={{ xs: 1 }}>
          <IconButton disableRipple onClick={() => onRemove(displayId)}>
            <CloseIcon color={data?.__typename === "NotFoundError" ? "error" : undefined} />
          </IconButton>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
