/**
 * @generated SignedSource<<53ac7862251675e1c86f1d9c941321a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkPrintDialogListItem_QuerySparePartsItemResult$data = {
  readonly __typename: "NotFoundError";
  readonly requestedId: string;
  readonly " $fragmentType": "BulkPrintDialogListItem_QuerySparePartsItemResult";
} | {
  readonly __typename: "SparePart";
  readonly description: string;
  readonly sparePartNumber: string;
  readonly " $fragmentType": "BulkPrintDialogListItem_QuerySparePartsItemResult";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BulkPrintDialogListItem_QuerySparePartsItemResult";
};
export type BulkPrintDialogListItem_QuerySparePartsItemResult$key = {
  readonly " $data"?: BulkPrintDialogListItem_QuerySparePartsItemResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkPrintDialogListItem_QuerySparePartsItemResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BulkPrintDialogListItem_QuerySparePartsItemResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestedId",
          "storageKey": null
        }
      ],
      "type": "NotFoundError",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sparePartNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "type": "SparePart",
      "abstractKey": null
    }
  ],
  "type": "QuerySparePartsItemResult",
  "abstractKey": "__isQuerySparePartsItemResult"
};

(node as any).hash = "834d89ddc74a7b470feeec6bf86e4dc7";

export default node;
