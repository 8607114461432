/**
 * @generated SignedSource<<10fa1e3a32f3d61f23fb92a4af5137fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartStorageLocationAutocomplete_StorageLocation$data = ReadonlyArray<{
  readonly description: string;
  readonly id: string;
  readonly " $fragmentType": "SparePartStorageLocationAutocomplete_StorageLocation";
}>;
export type SparePartStorageLocationAutocomplete_StorageLocation$key = ReadonlyArray<{
  readonly " $data"?: SparePartStorageLocationAutocomplete_StorageLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartStorageLocationAutocomplete_StorageLocation">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SparePartStorageLocationAutocomplete_StorageLocation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "StorageLocation",
  "abstractKey": null
};

(node as any).hash = "7b1dfe32199adb2a9b7bfecc96a5781b";

export default node;
