import { Button, useTheme } from "@mui/material";
import { FC } from "react";

import { Icons } from "../../utility/icons";

export const RoundPrintButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { palette } = useTheme();

  return (
    <Button
      onClick={onClick}
      sx={{ backgroundColor: palette.primary.main, color: "white", padding: 0, borderRadius: "50%", aspectRatio: 1 }}
    >
      <Icons.Print fontSize="large" />
    </Button>
  );
};
