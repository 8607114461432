import { Autocomplete, SxProps, TextField } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { fuzzySearch } from "../utils";

import {
  SparePartStorageLocationAutocomplete_StorageLocation$data,
  SparePartStorageLocationAutocomplete_StorageLocation$key,
} from "./__generated__/SparePartStorageLocationAutocomplete_StorageLocation.graphql";

type StorageLocation = NonNullable<SparePartStorageLocationAutocomplete_StorageLocation$data>[number];

export const SparePartStorageLocationAutocomplete: FC<{
  storageLocations: SparePartStorageLocationAutocomplete_StorageLocation$key;
  onChange: (id: string | undefined) => void;
  sx?: SxProps;
}> = ({ storageLocations: storageLocationsRef, onChange, sx }) => {
  const { translate } = useTranslation();

  const storageLocations = useFragment(
    graphql`
      fragment SparePartStorageLocationAutocomplete_StorageLocation on StorageLocation @relay(plural: true) {
        id
        description
      }
    `,
    storageLocationsRef,
  );

  return (
    <Autocomplete<StorageLocation>
      onChange={(_, value) => onChange(value?.id)}
      filterOptions={(options, state) => {
        return fuzzySearch(state.inputValue, options, ["description"]);
      }}
      getOptionLabel={({ description }) => description}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={storageLocations?.length === 0}
      //Sort the options by description
      options={storageLocations?.toSorted((a, b) => a.description.localeCompare(b.description)) ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate("SPARE_PART_STORAGE_LOCATION_AUTOCOMPLETE_PLACEHOLDER", "Choose a Storage Location...")}
        />
      )}
      sx={sx}
    />
  );
};
