import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { useMemo } from "react";

import pkg from "../../package.json";
import generatedIntrospection from "../__apollo__/generatedIntrospection";
import { useAreaAndProcessContext } from "../contexts/area";
import { getAccessToken } from "../msal";
import { getCrashReporter } from "../utility/crash-reporter";
import { getApolloGraphqlBaseUrl } from "../utility/environment";
import { hasValue } from "../utility/hasValue";
import { useTranslation } from "../utility/i18n/translation";

import {
  generateTransactionId,
  redactVariablesForSentry,
  reportGraphQLError,
  reportNetworkError,
  reportUnknownApolloError,
} from "./errorUtils";

// These are needed when working with unions and interfaces in fragments as we do with errors, see
// https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
export const createCache = (): InMemoryCache =>
  new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Profile: {
        fields: {
          selectedProcess: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          // The input for relayStylePagination describes the key in the cache, so different
          // input value combinations create different keys
          // https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
          confidentialityQuery: relayStylePagination([
            "input",
            ["areaId", "processId", "userSearchTerm", "createdAtFrom", "createdAtTo"],
          ]),
          ticketList: relayStylePagination([
            "input",
            [
              "processId",
              "priorities",
              "assignedToEmployee",
              "searchTerm",
              "sorting",
              "status",
              "completedFromDate",
              "completedToDate",
              "equipmentId",
              "locationId",
              "sublocationId",
            ],
          ]),
          equipmentHistory: relayStylePagination(["input", ["equipmentId", "completedDateRange", "priorities"]]),
        },
      },
    },
  });

export const apolloCache = createCache();

const errorLink: ApolloLink = onError(({ networkError, operation, graphQLErrors, response }) => {
  if (hasValue(graphQLErrors) && graphQLErrors.length > 0) {
    graphQLErrors.forEach((err) => reportGraphQLError(operation, err));

    return;
  }

  if (hasValue(networkError)) {
    reportNetworkError(operation, networkError);
  } else {
    reportUnknownApolloError({ operation, response });
  }
});

const breadcrumbLink = new ApolloLink((operation, forward) => {
  const { variables, operationName } = operation;
  const context = operation.getContext();
  const transactionId = hasValue(context.headers) ? context.headers["x-transaction-id"] : undefined;

  getCrashReporter().addBreadcrumb({
    category: "graphql",
    data: {
      request: operationName,
      variables: redactVariablesForSentry(variables),
      transactionId,
    },
  });

  return forward(operation);
});

const fetchApolloGraphQL = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  const token = await getAccessToken("select_account");
  const headers = hasValue(token)
    ? {
        ...init?.headers,
        Authorization: `Bearer ${token}`,
      }
    : {
        ...init?.headers,
      };

  return fetch(input, {
    ...init,
    headers,
  });
};

const useHeaderLink = (): ApolloLink => {
  const { locale } = useTranslation();
  const { selectedArea } = useAreaAndProcessContext();

  return useMemo(() => {
    const headers: Record<string, string> = {
      "x-user-language": locale,
      "x-plant-id": selectedArea.plantId,
      "x-area-id": selectedArea.id,
    };

    return setContext(async (_, { headers: currentHeaders }) => ({
      headers: {
        ...currentHeaders,
        ...headers,
      },
    }));
  }, [locale, selectedArea.id, selectedArea.plantId]);
};

const transactionIdLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-transaction-id": generateTransactionId(),
    },
  }));

  return forward(operation);
});

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> | undefined => {
  const headerLink = useHeaderLink();

  return useMemo(() => {
    const httpLink = new HttpLink({
      uri: getApolloGraphqlBaseUrl(),
      fetch: fetchApolloGraphQL,
    });

    return new ApolloClient({
      cache: apolloCache,
      name: "MST-Ticket-App",
      link: ApolloLink.from([transactionIdLink, breadcrumbLink, headerLink, errorLink, httpLink]),
      version: pkg.version,
    });
  }, [headerLink]);
};
