import { Dialog, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useTransition } from "react";
import { useQueryLoader } from "react-relay";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { hasValue } from "../../utility/hasValue";
import { PageErrorBoundary } from "../PageErrorBoundary";

import SparePartLabelBulkPrintDialogQuery, {
  SparePartLabelBulkPrintDialogQuery as SparePartLabelBulkPrintDialogQueryType,
} from "./__generated__/SparePartLabelBulkPrintDialogQuery.graphql";
import { SparePartLabelBulkPrintDialog } from "./SparePartLabelBulkPrintDialog";

export const SparePartLabelBulkPrintDialogContainer: FC<{
  open: boolean;
  onDismiss: () => void;
}> = ({ onDismiss, open }) => {
  const { palette } = useTheme();
  const [isInFlight, startTransition] = useTransition();

  const [queryRef, loadQuery, dispose] = useQueryLoader<SparePartLabelBulkPrintDialogQueryType>(
    SparePartLabelBulkPrintDialogQuery,
  );

  const refetchQuery = useCallback(
    (sparePartNumbers: string[]) => {
      startTransition(() => {
        loadQuery({
          input: { sparePartNumbers: sparePartNumbers },
        });
      });
    },
    [loadQuery],
  );

  // Run use effect only once to get initial query ref
  useEffect(() => {
    if (!hasValue(queryRef)) {
      loadQuery(
        {
          input: { sparePartNumbers: [] },
        },
        // Never use cache for this query, because it can prevent recovering from an error caught by the error boundary
        { fetchPolicy: "network-only" },
      );
    }
  }, [queryRef, loadQuery]);

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { backgroundColor: palette.background.default } }}
      // Needed for the input field autofocus to work
      disableRestoreFocus
    >
      <PageErrorBoundary onReset={dispose}>
        {queryRef ? (
          <SparePartLabelBulkPrintDialog
            onDismiss={onDismiss}
            load={refetchQuery}
            query={queryRef}
            isLoading={isInFlight}
          />
        ) : (
          <ActivityIndicator />
        )}
      </PageErrorBoundary>
    </Dialog>
  );
};
