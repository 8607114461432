/**
 * @generated SignedSource<<13d318391f26ec071b2fd5c4d1d15c40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MutationPrintSparePartLabelBulkInput = {
  printerId: string;
  quantity: number;
  sparePartIds: ReadonlyArray<string>;
  storageLocationId: string;
};
export type SparePartLabelBulkPrintDialogMutation$variables = {
  input: MutationPrintSparePartLabelBulkInput;
};
export type SparePartLabelBulkPrintDialogMutation$data = {
  readonly printSparePartLabelBulk: {
    readonly __typename: "MutationPrintSparePartLabelBulkSuccess";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SparePartLabelBulkPrintDialogMutation = {
  response: SparePartLabelBulkPrintDialogMutation$data;
  variables: SparePartLabelBulkPrintDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartLabelBulkPrintDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printSparePartLabelBulk",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "MutationPrintSparePartLabelBulkSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartLabelBulkPrintDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printSparePartLabelBulk",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a8fad2cfc5b41bde90526111f49ebc6",
    "id": null,
    "metadata": {},
    "name": "SparePartLabelBulkPrintDialogMutation",
    "operationKind": "mutation",
    "text": "mutation SparePartLabelBulkPrintDialogMutation(\n  $input: MutationPrintSparePartLabelBulkInput!\n) {\n  printSparePartLabelBulk(input: $input) {\n    __typename\n    ... on MutationPrintSparePartLabelBulkSuccess {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8bc4a0ba66b0b5a3f1b3ac1757c3e0b3";

export default node;
