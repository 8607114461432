import { Autocomplete, Skeleton, SxProps, TextField } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";
import { fuzzySearch } from "../utils";

import {
  PrinterAutocompletePrintersQuery,
  PrinterAutocompletePrintersQuery$data,
} from "./__generated__/PrinterAutocompletePrintersQuery.graphql";
import { PrinterAutocompleteViewerQuery } from "./__generated__/PrinterAutocompleteViewerQuery.graphql";

type Printer = NonNullable<PrinterAutocompletePrintersQuery$data["printers"]>[0];

export const ActualComponent: FC<{
  onPrinterSelected: (printerId: string | undefined) => void;
  disabled?: boolean;
  sx?: SxProps;
}> = ({ onPrinterSelected, disabled, sx }) => {
  const { translate } = useTranslation();
  const { printers } = useLazyLoadQuery<PrinterAutocompletePrintersQuery>(
    graphql`
      query PrinterAutocompletePrintersQuery {
        printers {
          id
          description
        }
      }
    `,
    {},
  );

  // Always refetch the default printer to make sure it's up to date. It is being set on printing, so the front-end has
  // no way of knowing if it changes until the user date is refetched.
  const { viewer } = useLazyLoadQuery<PrinterAutocompleteViewerQuery>(
    graphql`
      query PrinterAutocompleteViewerQuery {
        viewer {
          me {
            defaultPrinter {
              id
            }
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: "network-only",
    },
  );

  const [selectedPrinter, setSelectedPrinter] = useState<Printer | undefined>(
    printers?.find((p) => p.id === viewer?.me?.defaultPrinter?.id),
  );

  // Instead of calling onPrinterSelected in the onChange callback, we call it in a useEffect so it also fires with the initial value
  useEffect(() => {
    onPrinterSelected(selectedPrinter?.id);
  }, [onPrinterSelected, selectedPrinter]);

  return (
    <Autocomplete<Printer>
      onChange={(_, value) => {
        setSelectedPrinter(value ?? undefined);
      }}
      filterOptions={(options, state) => {
        return fuzzySearch(state.inputValue, options, ["description", "id"]);
      }}
      loading={disabled}
      value={selectedPrinter ?? null}
      getOptionLabel={({ description, id }) => `${description} (${id})`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={printers?.length === 0}
      options={printers ?? []}
      renderInput={(params) => (
        <TextField {...params} label={translate("PRINTER_AUTOCOMPLETE_PLACEHOLDER", "Choose a printer...")} />
      )}
      sx={sx}
    />
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={"90%"} />;
};

export const PrinterAutocomplete = skeletonify("PrinterAutocomplete", ActualComponent, SkeletonComponent);
