import { Button, Card, Grid2, Typography } from "@mui/material";
import { FC } from "react";

import { useTranslation } from "../../utility/i18n/translation";
import { GMAvatar } from "../shared/GMImageComponents";
import { PictureWithSignOutVariantProps } from "../shared/PictureAndSignOut";

export const ProfilePictureAndSignOutCard: FC<PictureWithSignOutVariantProps> = ({ me, onSignOutClicked }) => {
  const {
    firstName,
    lastName,
    profilePicture: { large: profilePicture },
  } = me;
  const { translate } = useTranslation();

  return (
    <Card>
      <Grid2 container alignItems="center" direction="column">
        <Grid2 container justifyContent="center" alignItems="center" size={{ xs: 12 }}>
          <Grid2 sx={{ aspectRatio: 1, height: "300px" }}>
            <GMAvatar {...me} pictureUri={profilePicture} />
          </Grid2>
        </Grid2>
        <Grid2 style={{ marginTop: 48 }}>
          <Typography variant="h3">
            {firstName} {lastName}
          </Typography>
        </Grid2>
        <Grid2 style={{ marginTop: 10 }}>
          <Button onClick={onSignOutClicked} style={{ textTransform: "none" }}>
            <Typography color="secondary">
              {translate("PROFILE.PICTURE_AND_SIGN_OUT.SIGN_OUT_BUTTON", "Sign out")}
            </Typography>
          </Button>
        </Grid2>
      </Grid2>
    </Card>
  );
};
