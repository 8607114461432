import { Button, Checkbox, FormControlLabel, Grid2, TextField, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";

import { hasValue } from "../../utility/hasValue";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { PrinterAutocomplete } from "../equipment-details/PrinterAutocomplete";

import { BulkPrintDialogList } from "./BulkPrintDialogList";
import { BulkPrintDialogListItem } from "./BulkPrintDialogListItem";
import { BulkPrintDialogStructure } from "./BulkPrintDialogStructure";
import {
  EquipmentLabelBulkPrintDialogListQuery,
  EquipmentLabelBulkPrintDialogListQuery$data,
} from "./__generated__/EquipmentLabelBulkPrintDialogListQuery.graphql";
import { EquipmentLabelBulkPrintDialogMutation } from "./__generated__/EquipmentLabelBulkPrintDialogMutation.graphql";

export const EquipmentLabelBulkPrintDialog: FC<{
  onDismiss: () => void;
  load: (equipmentNumbers: number[]) => void;
  query: PreloadedQuery<EquipmentLabelBulkPrintDialogListQuery>;
  isLoading?: boolean;
}> = ({ onDismiss, load, query: queryRef, isLoading }) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();
  const { showSnack } = useGMSnackbar();
  // Stores user input for querying
  const [equipmentNumbers, setEquipmentNumbers] = useState<string[]>([]);
  // Stores the current state of the list
  const [mutableEquipmentList, setMutableEquipmentList] = useState<
    EquipmentLabelBulkPrintDialogListQuery$data["equipmentList"]
  >([]);
  const [chosenPrinterId, setChosenPrinterId] = useState<string | undefined>(undefined);
  const [printQuantity, setPrintQuantity] = useState<number>(1);
  const [isMxLabel, setIsMxLabel] = useState<boolean>(false);

  const invalidEntries = useMemo(() => {
    const invalidEntries: number[] = [];
    mutableEquipmentList.forEach((fragment, index) => {
      if (fragment?.__typename === "NotFoundError") {
        invalidEntries.push(index + 1);
      }
    });
    return invalidEntries;
  }, [mutableEquipmentList]);

  // It is probably fine to only check if mutableEquipmentList has any entries, because we check for invalid entries right after
  const isFormCompleted = useMemo(
    () => hasValue(chosenPrinterId) && mutableEquipmentList.length > 0 && invalidEntries.length < 1,
    [chosenPrinterId, invalidEntries.length, mutableEquipmentList.length],
  );

  // Cannot be lazy loaded because it hangs re-rendering that prevents the loading spinner form showing inside the component
  const { equipmentList } = usePreloadedQuery<EquipmentLabelBulkPrintDialogListQuery>(
    graphql`
      query EquipmentLabelBulkPrintDialogListQuery($input: QueryEquipmentListInput!) {
        equipmentList(input: $input) {
          __typename
          ... on QueryEquipmentListItemSuccess {
            data {
              id
              equipmentNumber
            }
          }
          ... on NotFoundError {
            id
          }
          ...BulkPrintDialogListItem_QueryEquipmentListItemResult
        }
      }
    `,
    queryRef,
  );

  const [printMutation, mutationIsLoading] = useMutation<EquipmentLabelBulkPrintDialogMutation>(graphql`
    mutation EquipmentLabelBulkPrintDialogMutation($input: MutationPrintEquipmentLabelBulkInput!) {
      printEquipmentLabelBulk(input: $input) {
        ... on MutationPrintEquipmentLabelBulkSuccess {
          __typename
        }
      }
    }
  `);

  const onPrintPressed = useCallback(() => {
    if (isFormCompleted) {
      printMutation({
        variables: {
          input: {
            equipmentIds: mutableEquipmentList.map((fragment) => fragment?.data?.id).filter(hasValue),
            printerId: chosenPrinterId!, // We know that the value is set, otherwise isFormCompleted would be false
            quantity: printQuantity,
            isMxLabel: isMxLabel,
          },
        },
        onCompleted: () => {
          showSnack({
            message: translate("BULK_PRINT.ON_SUCCESS", "Your print queue has been sent to the selected printer"),
            variant: "success",
          });
          onDismiss();
        },
      });
    }
  }, [
    chosenPrinterId,
    isFormCompleted,
    isMxLabel,
    mutableEquipmentList,
    onDismiss,
    printMutation,
    printQuantity,
    showSnack,
    translate,
  ]);

  const onInputChange = useCallback(
    (data: string[]) => {
      // Use mutableEquipmentList, because equipmentNumbers is not updated when an item is removed to prevent refetch
      const currentListContent = mutableEquipmentList
        .map((fragment) => fragment.data?.equipmentNumber.toString() ?? fragment.id)
        .filter(hasValue);

      // Merge the new entries with the current entries of the list and remove duplicates
      setEquipmentNumbers([...new Set([...currentListContent, ...data])]);
    },
    [mutableEquipmentList],
  );

  // Remove items from mapped list to avoid unnecessary queries, but update parent state
  const onItemRemoved = useCallback((equipmentNumber: string) => {
    setMutableEquipmentList((currentState) =>
      currentState.filter(
        (item) => item.data?.equipmentNumber?.toString() !== equipmentNumber && item.id !== equipmentNumber,
      ),
    );
  }, []);

  // Memoize the rendered items to avoid re-rendering them on every input change. Improves performance when the list is long.
  const renderedListItems = useMemo(
    () =>
      mutableEquipmentList.map((fragment, index) =>
        fragment ? (
          <BulkPrintDialogListItem
            key={fragment.data?.id ?? fragment?.id}
            lineNumber={index + 1}
            onRemove={onItemRemoved}
            equipment={fragment}
          />
        ) : null,
      ),
    [mutableEquipmentList, onItemRemoved],
  );

  useEffect(() => {
    setMutableEquipmentList(equipmentList);
  }, [equipmentList, equipmentNumbers]);

  // Load more data when the user has added new equipment numbers
  useEffect(() => {
    load(equipmentNumbers.map((num) => parseInt(num, 10)));
  }, [equipmentNumbers, load]);

  return (
    <BulkPrintDialogStructure
      title={translate("BULK_PRINT.EQUIPMENT_DIALOG.TITLE", "Equipment Label Printing")}
      description={translate(
        "BULK_PRINT.EQUIPMENT_DIALOG.DESCRIPTION",
        "To create a bulk label list for printing, enter one equipment number at a time in the text field below or paste the list. Once you have filled in all fields, click the “Print” button.",
      )}
      content={
        <Grid2 container size="grow" direction="column" spacing={1}>
          <Grid2 container direction="column" spacing={2}>
            <Grid2>
              <Typography variant="body1" style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {translate("BULK_PRINT.PRINT_QUEUE", "Print Queue ({{count}})", { count: mutableEquipmentList.length })}
              </Typography>
            </Grid2>
            <Grid2>
              <BulkPrintDialogList
                onInput={onInputChange}
                isLoading={isLoading}
                sx={invalidEntries.length > 0 ? { border: `2px solid ${palette.error.main}` } : {}}
                inputPlaceholder={translate(
                  "BULK_PRINT.EQUIPMENT_DIALOG.INPUT_PLACEHOLDER",
                  "Write or paste Equipment IDs here",
                )}
              >
                <>{renderedListItems}</>
              </BulkPrintDialogList>
            </Grid2>
          </Grid2>
          {/* Error message */}
          {invalidEntries.length > 0 ? (
            <Grid2>
              <Typography variant="caption" color={palette.error.main}>
                {translate(
                  "BULK_PRINT.EQUIPMENT_DIALOG.INVALID_IDS_ERROR",
                  "One or more equipment IDs on line(s) [{{ids}}] are invalid. Please remove them from the queue and check it again.",
                  { ids: invalidEntries.join(", ") },
                )}
              </Typography>
            </Grid2>
          ) : null}
        </Grid2>
      }
      printSettings={
        <>
          {/* MX label checkbox */}
          <Grid2 size="auto">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsMxLabel(e.target.checked)} />}
              label={translate("BULK_PRINT.EQUIPMENT_DIALOG.MX_CHECKBOX_LABEL", "MX Label")}
            />
          </Grid2>

          {/* Print settings */}
          <Grid2 container direction="row" spacing={2}>
            {/* Printer name input */}
            <Grid2 size={{ xs: 6 }}>
              <Grid2>
                <Typography variant="body1">{translate("BULK_PRINT.PRINTER_INPUT.TITLE", "Printer")}</Typography>
              </Grid2>
              <Grid2>
                <PrinterAutocomplete.Suspense
                  onPrinterSelected={setChosenPrinterId}
                  sx={{ backgroundColor: palette.background.paper }}
                />
              </Grid2>
            </Grid2>
            {/* Amount input */}
            <Grid2 size={{ xs: 6 }}>
              <Grid2>
                <Typography variant="body1">
                  {translate("BULK_PRINT.AMOUNT_INPUT.TITLE", "Amount of Copies")}
                </Typography>
              </Grid2>
              <Grid2>
                <TextField
                  sx={{ backgroundColor: palette.background.paper }}
                  fullWidth
                  type="number"
                  value={printQuantity}
                  onChange={(e) => setPrintQuantity(parseInt(e.target.value, 10))}
                  slotProps={{ htmlInput: { min: 1 } }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </>
      }
      actions={
        <>
          <Button color="primary" sx={{ mr: 4 }} onClick={onDismiss}>
            {translate("MISC.CANCEL", "Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isFormCompleted}
            onClick={onPrintPressed}
            loading={mutationIsLoading}
          >
            {translate("EQUIPMENT_HEADER.FAB.PRINT_DIALOG.PRINT_BUTTON", "Print")}
          </Button>
        </>
      }
    />
  );
};
