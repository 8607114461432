import { hasValue } from "@lego/mst-error-utilities";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Divider,
  Grid2,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, SyntheticEvent, useState } from "react";
import { useFragment } from "react-relay";

import { Icons } from "../../../utility/icons";
import { skeletonify } from "../../skeleton";

import { SparePartBomList_BomListNode$key } from "./__generated__/SparePartBomList_BomListNode.graphql";
import { BomSparePartRow } from "./BomSparePartRow";
import { isBomOfType } from "./SparePartBom";

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  boxShadow: "none",
  paddingRight: 0,
  "&.Mui-expanded": {
    marginTop: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(() => ({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    "&.Mui-expanded": {
      marginTop: "12px",
    },
  },
}));

const ActualComponent: FC<{
  id: string;
  showSeparator: boolean;
  nodes: SparePartBomList_BomListNode$key | null;
}> = (props) => {
  const { id, showSeparator, nodes: nodesRef } = props;
  const [expanded, setExpanded] = useState(false);

  const data = useFragment(
    graphql`
      fragment SparePartBomList_BomListNode on BomListNode
      @argumentDefinitions(processId: { type: "ID!" })
      @relay(plural: true) {
        id
        key
        parentKey
        item {
          ... on SparePart {
            __typename
            id
            ...BomSparePartRow_sparePart @arguments(processId: $processId)
          }
          ... on BomCategory {
            __typename
            id
            description
          }
        }
      }
    `,
    nodesRef,
  );

  const handleChange = () => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const filteredData = (data ?? []).filter(isBomOfType("BomCategory", "SparePart"));
  const me = filteredData.find((x) => x.id === id);

  const myChildren = filteredData.filter((x) => {
    return x.parentKey === me?.key;
  });
  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }} style={{ position: "relative" }}>
        {hasValue(me?.parentKey) && (
          <>
            <Divider
              sx={{
                width: "18px",
                top: me?.item.__typename === "SparePart" ? "81px" : "46px",
                left: "-20px",
                borderBottomWidth: "2px",
                zIndex: 100,
                position: "absolute",
              }}
            />
            <Divider
              orientation="vertical"
              style={{
                borderRightWidth: "2px",
                height: "100%",
                top: "-51px",
                left: "-21px",
                position: "absolute",
                zIndex: 0,
              }}
            />
          </>
        )}
        {showSeparator && <Divider style={{ borderBottomWidth: "2px" }} />}
        {me?.item.__typename === "SparePart" && <BomSparePartRow sparePart={me.item} />}
        {me?.item.__typename === "BomCategory" && (
          <StyledAccordion expanded={expanded} onChange={handleChange()}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                style={{
                  position: "relative",
                  marginTop: "4px",
                  zIndex: 1,
                  backgroundColor: "InfoBackground",
                }}
              >
                <Icons.Burger sx={{ mr: 3 }} />
              </Box>
              <Grid2 container direction="column">
                <Grid2>
                  <Typography sx={{ color: "text.secondary" }}>{me?.item.id}</Typography>
                </Grid2>
                <Grid2>
                  <Typography>{me?.item.description}</Typography>
                </Grid2>
              </Grid2>
            </StyledAccordionSummary>
            <AccordionDetails style={{ paddingRight: 0, paddingLeft: 32 }}>
              {myChildren.map((x, index) => (
                <SparePartBomList nodes={nodesRef} showSeparator={index > 0} id={x.id} key={x.id} />
              ))}
            </AccordionDetails>
          </StyledAccordion>
        )}
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => {
  return <Skeleton variant="text" width={100} />;
};

export const SparePartBomList = skeletonify("SparePartBomList", ActualComponent, SkeletonComponent);
