import { Grid2, Paper, SxProps, TextField, TextFieldProps } from "@mui/material";
import { FC, JSX, useState } from "react";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";

export const BulkPrintDialogList: FC<{
  onInput: (data: string[]) => void;
  sx?: SxProps;
  isLoading?: boolean;
  inputPlaceholder?: string;
  children?: JSX.Element;
}> = ({ onInput, sx, isLoading = false, inputPlaceholder, children }) => {
  const [input, setInput] = useState("");

  const onInputChange: TextFieldProps["onChange"] = (event) => {
    const value = event.target.value;
    if (value.includes("\n")) {
      const inputIds = value
        .split("\n") // Split the input by newlines to get the individual IDs
        .map((x) => x.trim()) // Trim the whitespace from the IDs
        .filter((x) => x !== ""); // Filter out empty strings

      onInput(inputIds); // Pass the updated IDs to the parent component so query reload is triggered
      setInput(""); // Reset the input field
    } else {
      setInput(value);
    }
  };

  return (
    <Paper style={{ fontSize: "large", flexWrap: "nowrap", overflow: "auto", maxHeight: 450 }} sx={sx}>
      <Grid2 container direction="column" spacing={2}>
        {children}
        {isLoading ? (
          <Grid2 container justifyContent="center">
            <ActivityIndicator />
          </Grid2>
        ) : null}
        <Grid2 px={2}>
          <TextField
            fullWidth
            multiline
            placeholder={inputPlaceholder}
            onChange={onInputChange}
            value={input}
            autoFocus
          />
        </Grid2>
      </Grid2>
    </Paper>
  );
};
