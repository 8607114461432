/**
 * @generated SignedSource<<beb749bf64d712f92b8f9d02be80a2a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryEquipmentListInput = {
  equipmentNumbers: ReadonlyArray<number>;
};
export type EquipmentLabelBulkPrintDialogListQuery$variables = {
  input: QueryEquipmentListInput;
};
export type EquipmentLabelBulkPrintDialogListQuery$data = {
  readonly equipmentList: ReadonlyArray<{
    readonly __typename: string;
    readonly data?: {
      readonly equipmentNumber: number;
      readonly id: string;
    };
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"BulkPrintDialogListItem_QueryEquipmentListItemResult">;
  }>;
};
export type EquipmentLabelBulkPrintDialogListQuery = {
  response: EquipmentLabelBulkPrintDialogListQuery$data;
  variables: EquipmentLabelBulkPrintDialogListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equipmentNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquipmentLabelBulkPrintDialogListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "equipmentList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryEquipmentListItemSuccess",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "NotFoundError",
            "abstractKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BulkPrintDialogListItem_QueryEquipmentListItemResult"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquipmentLabelBulkPrintDialogListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "equipmentList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryEquipmentListItemSuccess",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestedId",
                "storageKey": null
              }
            ],
            "type": "NotFoundError",
            "abstractKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isQueryEquipmentListItemResult"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b62ca02a06565e589df8423b88bc76d9",
    "id": null,
    "metadata": {},
    "name": "EquipmentLabelBulkPrintDialogListQuery",
    "operationKind": "query",
    "text": "query EquipmentLabelBulkPrintDialogListQuery(\n  $input: QueryEquipmentListInput!\n) {\n  equipmentList(input: $input) {\n    __typename\n    ... on QueryEquipmentListItemSuccess {\n      data {\n        __typename\n        id\n        equipmentNumber\n      }\n    }\n    ... on NotFoundError {\n      id\n    }\n    ...BulkPrintDialogListItem_QueryEquipmentListItemResult\n  }\n}\n\nfragment BulkPrintDialogListItem_QueryEquipmentListItemResult on QueryEquipmentListItemResult {\n  __isQueryEquipmentListItemResult: __typename\n  __typename\n  ... on NotFoundError {\n    requestedId\n  }\n  ... on QueryEquipmentListItemSuccess {\n    data {\n      __typename\n      equipmentNumber\n      description\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "008ca9854f46d8c735bae0ce191fb6e1";

export default node;
