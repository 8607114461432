/**
 * @generated SignedSource<<d7d722fd90103452bd4030f46cfa96df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PrinterAutocompleteViewerQuery$variables = Record<PropertyKey, never>;
export type PrinterAutocompleteViewerQuery$data = {
  readonly viewer: {
    readonly me: {
      readonly defaultPrinter: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  };
};
export type PrinterAutocompleteViewerQuery = {
  response: PrinterAutocompleteViewerQuery$data;
  variables: PrinterAutocompleteViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Printer",
  "kind": "LinkedField",
  "name": "defaultPrinter",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrinterAutocompleteViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employee",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrinterAutocompleteViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employee",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fae1f19b1c3d9eab309e64d1719d5d40",
    "id": null,
    "metadata": {},
    "name": "PrinterAutocompleteViewerQuery",
    "operationKind": "query",
    "text": "query PrinterAutocompleteViewerQuery {\n  viewer {\n    me {\n      defaultPrinter {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "47bb2fa38c5fac305a9e718eb96d6796";

export default node;
