import { Autocomplete, SxProps, TextField } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC } from "react";
import { useFragment } from "react-relay";

import { useTranslation } from "../../utility/i18n/translation";
import { fuzzySearch } from "../utils";

import {
  MroPlantAutocomplete_MroPlant$data,
  MroPlantAutocomplete_MroPlant$key,
} from "./__generated__/MroPlantAutocomplete_MroPlant.graphql";

type MroPlant = NonNullable<MroPlantAutocomplete_MroPlant$data>[number];

export const MroPlantAutocomplete: FC<{
  mroPlants: MroPlantAutocomplete_MroPlant$key;
  onChange: (id: string | undefined) => void;
  defaultValue?: string;
  sx?: SxProps;
}> = ({ mroPlants: mroPlantsRef, onChange, defaultValue, sx }) => {
  const { translate } = useTranslation();

  const mroPlants = useFragment(
    graphql`
      fragment MroPlantAutocomplete_MroPlant on MroPlant @relay(plural: true) {
        id
        description
        mroNumber
      }
    `,
    mroPlantsRef,
  );

  return (
    <Autocomplete<MroPlant>
      onChange={(_, value) => onChange(value?.id)}
      filterOptions={(options, state) => {
        return fuzzySearch(state.inputValue, options, ["mroNumber", "description"]);
      }}
      getOptionLabel={({ description, mroNumber }) => `${mroNumber} ${description}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={mroPlants?.length === 0}
      defaultValue={mroPlants?.find((x) => x.id === defaultValue) ?? null}
      // Sort the options ascending by mroNumber
      options={mroPlants?.toSorted((a, b) => a.mroNumber - b.mroNumber) ?? []}
      renderInput={(params) => (
        <TextField {...params} label={translate("MRO_PLANT_AUTOCOMPLETE_PLACEHOLDER", "Choose an MRO plant...")} />
      )}
      sx={sx}
    />
  );
};
