/**
 * @generated SignedSource<<f972056cfed729b7fd011f380499eed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MutationPrintEquipmentLabelBulkInput = {
  equipmentIds: ReadonlyArray<string>;
  isMxLabel?: boolean | null | undefined;
  printerId: string;
  quantity: number;
};
export type EquipmentLabelBulkPrintDialogMutation$variables = {
  input: MutationPrintEquipmentLabelBulkInput;
};
export type EquipmentLabelBulkPrintDialogMutation$data = {
  readonly printEquipmentLabelBulk: {
    readonly __typename: "MutationPrintEquipmentLabelBulkSuccess";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type EquipmentLabelBulkPrintDialogMutation = {
  response: EquipmentLabelBulkPrintDialogMutation$data;
  variables: EquipmentLabelBulkPrintDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquipmentLabelBulkPrintDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printEquipmentLabelBulk",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "MutationPrintEquipmentLabelBulkSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquipmentLabelBulkPrintDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printEquipmentLabelBulk",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "275a90f26398cbc17cfaee18116b0c4e",
    "id": null,
    "metadata": {},
    "name": "EquipmentLabelBulkPrintDialogMutation",
    "operationKind": "mutation",
    "text": "mutation EquipmentLabelBulkPrintDialogMutation(\n  $input: MutationPrintEquipmentLabelBulkInput!\n) {\n  printEquipmentLabelBulk(input: $input) {\n    __typename\n    ... on MutationPrintEquipmentLabelBulkSuccess {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c045f14814e5d2b2a8c95957ec88062c";

export default node;
