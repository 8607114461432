import { hasValue } from "@lego/mst-error-utilities";
import { Grid2 } from "@mui/material";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";

import { TicketPriorityEnum } from "../../__apollo__/graphql";

import { CmsTicketFormProps } from "./CmsCreateTicket";
import { CmsEquipmentSearch } from "./CmsEquipmentSearch";
import { useCmsCreateEquipmentRepairTicketMutation } from "./CmsMutations";
import { CmsTicketDescription } from "./CmsTicketDescription";
import { CmsTicketEndDate } from "./CmsTicketEndDate";
import { CmsTicketPriority } from "./CmsTicketPriority";
import { CmsTicketSubmitButtons } from "./CmsTicketSubmitButtons";
import { CMSTicketsPriorityInput } from "./__generated__/CmsMutationsEquipmentRepairMutation.graphql";

export const CmsEquipmentRepairForm: FC<CmsTicketFormProps> = ({ onComplete }) => {
  const [mutation, loading] = useCmsCreateEquipmentRepairTicketMutation();
  const [equipmentId, setEquipmentId] = useState<string | null>();
  const [priority, setPriority] = useState<CMSTicketsPriorityInput>();
  const [requiredEndDate, setRequiredEndDate] = useState<Date | null>();
  const [description, setDescription] = useState<string | null>();

  const endDateRequired = priority === TicketPriorityEnum.Priority8;
  const formCompleted =
    hasValue(equipmentId) &&
    hasValue(priority) &&
    hasValue(description) &&
    (!endDateRequired || hasValue(requiredEndDate));

  const onClick = useCallback(
    (assign: boolean) => {
      if (!formCompleted) {
        return;
      }
      mutation({
        variables: {
          input: {
            assignToSelf: assign,
            equipmentId: equipmentId,
            priority: priority,
            requiredEndDate: endDateRequired && requiredEndDate ? format(requiredEndDate, "yyyy-MM-dd") : undefined,
            description,
          },
        },
        onCompleted: ({ result }) => {
          const ticketNumber = result?.data?.ticketNumber;
          if (hasValue(ticketNumber) && hasValue(onComplete)) {
            onComplete(ticketNumber, assign);
          }
        },
      });
    },
    [formCompleted, mutation, equipmentId, priority, endDateRequired, requiredEndDate, description, onComplete],
  );

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <CmsEquipmentSearch onChange={setEquipmentId} />
      </Grid2>
      <Grid2>
        <CmsTicketPriority onChange={setPriority} />
      </Grid2>
      {endDateRequired ? (
        <Grid2>
          <CmsTicketEndDate value={requiredEndDate ?? null} onChange={setRequiredEndDate} />
        </Grid2>
      ) : null}
      <Grid2>
        <CmsTicketDescription onChange={setDescription} />
      </Grid2>
      <Grid2>
        <CmsTicketSubmitButtons loading={loading} submit={onClick} disabled={!formCompleted} />
      </Grid2>
    </Grid2>
  );
};
