import { Dialog, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useTransition } from "react";
import { useQueryLoader } from "react-relay";

import { ActivityIndicator } from "../../components/shared/ActivityIndicator";
import { hasValue } from "../../utility/hasValue";
import { PageErrorBoundary } from "../PageErrorBoundary";

import EquipmentLabelBulkPrintDialogListQuery, {
  EquipmentLabelBulkPrintDialogListQuery as EquipmentLabelBulkPrintDialogListQueryType,
} from "./__generated__/EquipmentLabelBulkPrintDialogListQuery.graphql";
import { EquipmentLabelBulkPrintDialog } from "./EquipmentLabelBulkPrintDialog";

export const EquipmentLabelBulkPrintDialogContainer: FC<{
  open: boolean;
  onDismiss: () => void;
}> = ({ onDismiss, open }) => {
  const { palette } = useTheme();
  const [isInFlight, startTransition] = useTransition();

  const [queryRef, loadQuery, dispose] = useQueryLoader<EquipmentLabelBulkPrintDialogListQueryType>(
    EquipmentLabelBulkPrintDialogListQuery,
  );

  const refetchQuery = useCallback(
    (equipmentNumbers: number[]) => {
      // Filter out invalid equipment numbers, because GQL can only handle 32-bit integers.
      const validEquipmentNumbers = equipmentNumbers.filter((num) => !isNaN(num) && num < Math.pow(2, 31) - 1);
      startTransition(() => {
        loadQuery({
          input: { equipmentNumbers: validEquipmentNumbers },
        });
      });
    },
    [loadQuery],
  );

  // Run use effect only once to get initial query ref
  useEffect(() => {
    if (!hasValue(queryRef)) {
      loadQuery(
        {
          input: { equipmentNumbers: [] },
        },
        // Never use cache for this query, because it can prevent recovering from an error caught by the error boundary
        { fetchPolicy: "network-only" },
      );
    }
  }, [loadQuery, queryRef]);

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { backgroundColor: palette.background.default } }}
      // Needed for the input field autofocus to work
      disableRestoreFocus
    >
      <PageErrorBoundary onReset={dispose}>
        {queryRef ? (
          <EquipmentLabelBulkPrintDialog
            onDismiss={onDismiss}
            load={refetchQuery}
            query={queryRef}
            isLoading={isInFlight}
          />
        ) : (
          <ActivityIndicator />
        )}
      </PageErrorBoundary>
    </Dialog>
  );
};
