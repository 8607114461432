/**
 * @generated SignedSource<<186baa421fd5f9da44bda74c1932958d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartBomList_BomListNode$data = ReadonlyArray<{
  readonly id: string;
  readonly item: {
    readonly __typename: "BomCategory";
    readonly description: string;
    readonly id: string;
  } | {
    readonly __typename: "SparePart";
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BomSparePartRow_sparePart">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly key: string;
  readonly parentKey: string | null | undefined;
  readonly " $fragmentType": "SparePartBomList_BomListNode";
}>;
export type SparePartBomList_BomListNode$key = ReadonlyArray<{
  readonly " $data"?: SparePartBomList_BomListNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartBomList_BomListNode">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "processId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SparePartBomList_BomListNode",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "processId",
                  "variableName": "processId"
                }
              ],
              "kind": "FragmentSpread",
              "name": "BomSparePartRow_sparePart"
            }
          ],
          "type": "SparePart",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "type": "BomCategory",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BomListNode",
  "abstractKey": null
};
})();

(node as any).hash = "b66a59a83cb0ccff7fb4aceadbe43a17";

export default node;
