import { hasValue } from "@lego/mst-error-utilities";
import { Grid2 } from "@mui/material";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";

import { TicketPriorityEnum } from "../../__apollo__/graphql";
import { SparePartSearchProvider } from "../dashboard/spare-part-bom/spare-part-search-context";

import { CmsTicketFormProps } from "./CmsCreateTicket";
import { CmsEquipmentSearch } from "./CmsEquipmentSearch";
import { useCmsCreateSparepartRepairTicketMutation } from "./CmsMutations";
import { CmsSparePartSearch } from "./CmsSparePartSearch";
import { CmsTicketDescription } from "./CmsTicketDescription";
import { CmsTicketEndDate } from "./CmsTicketEndDate";
import { CmsTicketPriority } from "./CmsTicketPriority";
import { CmsTicketSubmitButtons } from "./CmsTicketSubmitButtons";
import { CMSTicketsPriorityInput } from "./__generated__/CmsMutationsEquipmentRepairMutation.graphql";

export const CmsSparePartRepairForm: FC<CmsTicketFormProps> = ({ onComplete }) => {
  const [mutation, loading] = useCmsCreateSparepartRepairTicketMutation();

  const [equipmentId, setEquipmentId] = useState<string | null>();
  const [sparePartId, setSparePartId] = useState<string | null>();
  const [priority, setPriority] = useState<CMSTicketsPriorityInput>();
  const [requiredEndDate, setRequiredEndDate] = useState<Date | null>();
  const [description, setDescription] = useState<string | null>();

  const endDateRequired = priority === TicketPriorityEnum.Priority8;
  const formCompleted =
    hasValue(equipmentId) &&
    hasValue(sparePartId) &&
    hasValue(description) &&
    hasValue(priority) &&
    (endDateRequired ? hasValue(requiredEndDate) : true);
  const onClick = useCallback(
    (assign: boolean) => {
      if (!formCompleted) {
        return;
      }
      mutation({
        variables: {
          input: {
            assignToSelf: assign,
            equipmentId: equipmentId,
            sparePartId: sparePartId,
            priority: priority,
            requiredEndDate: endDateRequired && requiredEndDate ? format(requiredEndDate, "yyyy-MM-dd") : undefined,
            description,
          },
        },
        onCompleted: ({ result }) => {
          const ticketNumber = result?.data?.ticketNumber;
          if (hasValue(ticketNumber) && hasValue(onComplete)) {
            onComplete(ticketNumber, assign);
          }
        },
      });
    },
    [
      formCompleted,
      mutation,
      equipmentId,
      sparePartId,
      priority,
      endDateRequired,
      requiredEndDate,
      description,
      onComplete,
    ],
  );

  return (
    <Grid2 container direction="column" spacing={3}>
      <Grid2>
        <CmsEquipmentSearch onChange={setEquipmentId} />
      </Grid2>
      <Grid2>
        <SparePartSearchProvider>
          <CmsSparePartSearch onChange={setSparePartId} />
        </SparePartSearchProvider>
      </Grid2>
      <Grid2>
        <CmsTicketPriority onChange={setPriority} />
      </Grid2>
      {priority === TicketPriorityEnum.Priority8 ? (
        <Grid2>
          <CmsTicketEndDate onChange={(value) => setRequiredEndDate(value)} value={requiredEndDate ?? null} />
        </Grid2>
      ) : null}
      <Grid2>
        <CmsTicketDescription onChange={setDescription} />
      </Grid2>
      <Grid2>
        <CmsTicketSubmitButtons loading={loading} submit={(assign) => onClick(assign)} disabled={!formCompleted} />
      </Grid2>
    </Grid2>
  );
};
