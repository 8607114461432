/**
 * @generated SignedSource<<85cf50c231c67a4263fd0e7704f9526f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PrinterAutocompletePrintersQuery$variables = Record<PropertyKey, never>;
export type PrinterAutocompletePrintersQuery$data = {
  readonly printers: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
  }> | null | undefined;
};
export type PrinterAutocompletePrintersQuery = {
  response: PrinterAutocompletePrintersQuery$data;
  variables: PrinterAutocompletePrintersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Printer",
    "kind": "LinkedField",
    "name": "printers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrinterAutocompletePrintersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrinterAutocompletePrintersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f88e700fcca748d55b691c47896703eb",
    "id": null,
    "metadata": {},
    "name": "PrinterAutocompletePrintersQuery",
    "operationKind": "query",
    "text": "query PrinterAutocompletePrintersQuery {\n  printers {\n    id\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "7118df1ebde3d737a3fc1ce84386ad40";

export default node;
