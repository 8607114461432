import { Grid2, Skeleton, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { hasValue } from "../../utility/hasValue";
import { useTranslation } from "../../utility/i18n/translation";
import { skeletonify } from "../skeleton";

import { MroPlantAutocomplete } from "./MroPlantAutocomplete";
import { SparePartStorageLocationAutocomplete } from "./SparePartStorageLocationAutocomplete";
import { SparePartLabelBulkPrintMroAndStorageLocationInputsQuery } from "./__generated__/SparePartLabelBulkPrintMroAndStorageLocationInputsQuery.graphql";

const ActualComponent: FC<{
  onPlantChange?: (id: string | undefined) => void;
  onStorageLocationChange?: (id: string | undefined) => void;
}> = ({ onPlantChange, onStorageLocationChange }) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();

  const { plants, viewer } = useLazyLoadQuery<SparePartLabelBulkPrintMroAndStorageLocationInputsQuery>(
    graphql`
      query SparePartLabelBulkPrintMroAndStorageLocationInputsQuery {
        plants {
          mroPlants {
            id
            ...MroPlantAutocomplete_MroPlant
            storageLocations {
              ...SparePartStorageLocationAutocomplete_StorageLocation
            }
          }
        }
        viewer {
          me {
            process {
              plant {
                mroPlants {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {},
  );

  // Only set default plant if there is exactly one option connecting to the user's plant
  const defaultPlantId = useMemo(() => {
    if (viewer.me?.process?.plant?.mroPlants?.length === 1) {
      const defaultId = viewer.me.process.plant.mroPlants[0].id;
      onPlantChange?.(defaultId);

      return defaultId;
    }
  }, [onPlantChange, viewer]);

  const [selectedPlant, setSelectedPlant] = useState<string | undefined>(defaultPlantId);

  const mroPlants = useMemo(() => plants?.flatMap((x) => x.mroPlants).filter(hasValue) ?? [], [plants]);
  const storageLocations = useMemo(
    () => mroPlants.find((x) => x.id === selectedPlant)?.storageLocations ?? [],
    [mroPlants, selectedPlant],
  );

  const handlePlantChanged = useCallback(
    (id: string | undefined) => {
      setSelectedPlant(id);
      onPlantChange?.(id);
    },
    [onPlantChange],
  );

  const handleStorageLocationChanged = useCallback(
    (id: string | undefined) => {
      onStorageLocationChange?.(id);
    },
    [onStorageLocationChange],
  );

  return (
    // Max height is a hack to prevent magic empty spaces from appearing
    <Grid2 container direction="row" spacing={2} maxHeight={100}>
      {/* Plant input */}
      <Grid2 size={{ xs: 6 }}>
        <Grid2>
          <Typography variant="body1">
            {translate("BULK_PRINT.SPARE_PART_DIALOG.PLANT_INPUT.TITLE", "Plant")}
          </Typography>
        </Grid2>
        <Grid2>
          <MroPlantAutocomplete
            mroPlants={mroPlants}
            onChange={handlePlantChanged}
            defaultValue={defaultPlantId}
            sx={{ backgroundColor: palette.background.paper }}
          />
        </Grid2>
      </Grid2>
      {/* Storage location input */}
      <Grid2 size={{ xs: 6 }}>
        <Grid2>
          <Typography variant="body1">
            {translate("BULK_PRINT.SPARE_PART_DIALOG.STORAGE_LOCATION_INPUT.TITLE", "Storage Location")}
          </Typography>
        </Grid2>
        <Grid2>
          <SparePartStorageLocationAutocomplete
            storageLocations={storageLocations}
            onChange={handleStorageLocationChanged}
            sx={{ backgroundColor: palette.background.paper }}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const SkeletonComponent: FC = () => {
  return (
    <Grid2 container direction="row" spacing={2} maxHeight={100}>
      <Grid2 size={{ xs: 6 }}>
        <Skeleton variant="rectangular" />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <Skeleton variant="rectangular" />
      </Grid2>
    </Grid2>
  );
};

export const SparePartLabelBulkPrintMroAndStorageLocationInputs = skeletonify(
  "SparePartLabelBulkPrintMroAndStorageLocationInputs",
  ActualComponent,
  SkeletonComponent,
);
