/**
 * @generated SignedSource<<5e17a2e8765704193a131bfd9b2ed022>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketRepairDocumentationStandardRow_comments$data = ReadonlyArray<{
  readonly description: string;
  readonly timestampWithOffset: string;
  readonly " $fragmentType": "TicketRepairDocumentationStandardRow_comments";
}>;
export type TicketRepairDocumentationStandardRow_comments$key = ReadonlyArray<{
  readonly " $data"?: TicketRepairDocumentationStandardRow_comments$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketRepairDocumentationStandardRow_comments">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TicketRepairDocumentationStandardRow_comments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestampWithOffset",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "52cf601bc28dd5e58afa5257f60146a2";

export default node;
