import { DialogActions, DialogContent, DialogTitle, Grid2, Typography, useMediaQuery } from "@mui/material";
import { FC, JSX } from "react";

import { Icons } from "../../utility/icons";

type BulkPrintDialogStructureProps = {
  title: string;
  description: string;
  content: JSX.Element;
  actions: JSX.Element;
  printSettings: JSX.Element;
};

export const BulkPrintDialogStructure: FC<BulkPrintDialogStructureProps> = ({
  title,
  description,
  content,
  actions,
  printSettings,
}) => {
  // Hide bloat on smaller screens
  const isViewHeightLg = useMediaQuery("(min-height: 1052px)");

  return (
    <>
      <DialogTitle>
        <Grid2 container direction="column" alignItems="center" spacing={1}>
          {isViewHeightLg ? (
            <Grid2>
              <Icons.Print fontSize="large" />
            </Grid2>
          ) : null}
          <Grid2>
            <Typography variant="h3">{title}</Typography>
          </Grid2>
          {isViewHeightLg ? (
            <Grid2>
              <Typography variant="body1" color="textSecondary">
                {description}
              </Typography>
            </Grid2>
          ) : null}
        </Grid2>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" spacing={2}>
          <Grid2 container justifyContent="center" size={{ xs: 12 }}>
            {content}
          </Grid2>

          {/* Print settings */}
          <Grid2 container direction="column" spacing={1}>
            {printSettings}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </>
  );
};
