/**
 * @generated SignedSource<<dbc796bf0e70022a7423d9de437de2c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuerySparePartsInput = {
  sparePartNumbers: ReadonlyArray<string>;
};
export type SparePartLabelBulkPrintDialogQuery$variables = {
  input: QuerySparePartsInput;
};
export type SparePartLabelBulkPrintDialogQuery$data = {
  readonly spareParts: ReadonlyArray<{
    readonly __typename: string;
    readonly requestedId?: string;
    readonly sparePartId?: string;
    readonly sparePartNumber?: string;
    readonly " $fragmentSpreads": FragmentRefs<"BulkPrintDialogListItem_QuerySparePartsItemResult">;
  }>;
};
export type SparePartLabelBulkPrintDialogQuery = {
  response: SparePartLabelBulkPrintDialogQuery$data;
  variables: SparePartLabelBulkPrintDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": "sparePartId",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sparePartNumber",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestedId",
      "storageKey": null
    }
  ],
  "type": "NotFoundError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartLabelBulkPrintDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spareParts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "SparePart",
            "abstractKey": null
          },
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BulkPrintDialogListItem_QuerySparePartsItemResult"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SparePartLabelBulkPrintDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "spareParts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "type": "SparePart",
            "abstractKey": null
          },
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isQuerySparePartsItemResult"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4d903b8b3a81044984e07827e1c51c2",
    "id": null,
    "metadata": {},
    "name": "SparePartLabelBulkPrintDialogQuery",
    "operationKind": "query",
    "text": "query SparePartLabelBulkPrintDialogQuery(\n  $input: QuerySparePartsInput!\n) {\n  spareParts(input: $input) {\n    __typename\n    ... on SparePart {\n      sparePartId: id\n      sparePartNumber\n    }\n    ... on NotFoundError {\n      requestedId\n    }\n    ...BulkPrintDialogListItem_QuerySparePartsItemResult\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment BulkPrintDialogListItem_QuerySparePartsItemResult on QuerySparePartsItemResult {\n  __isQuerySparePartsItemResult: __typename\n  __typename\n  ... on NotFoundError {\n    requestedId\n  }\n  ... on SparePart {\n    sparePartNumber\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "24a14e0e22bd85e8954480a4fffe5bc5";

export default node;
