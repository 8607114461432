import { Button, Grid2, TextField, Typography, useTheme } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";

import { hasValue } from "../../utility/hasValue";
import { useTranslation } from "../../utility/i18n/translation";
import { useGMSnackbar } from "../../utility/snackbar";
import { PrinterAutocomplete } from "../equipment-details/PrinterAutocomplete";

import { BulkPrintDialogList } from "./BulkPrintDialogList";
import { BulkPrintDialogListItem } from "./BulkPrintDialogListItem";
import { BulkPrintDialogStructure } from "./BulkPrintDialogStructure";
import { SparePartLabelBulkPrintMroAndStorageLocationInputs } from "./SparePartLabelBulkPrintMroAndStorageLocationInputs";
import { SparePartLabelBulkPrintDialogMutation } from "./__generated__/SparePartLabelBulkPrintDialogMutation.graphql";
import {
  SparePartLabelBulkPrintDialogQuery,
  SparePartLabelBulkPrintDialogQuery$data,
} from "./__generated__/SparePartLabelBulkPrintDialogQuery.graphql";

export const SparePartLabelBulkPrintDialog: FC<{
  onDismiss: () => void;
  load: (sparePartNumbers: string[]) => void;
  query: PreloadedQuery<SparePartLabelBulkPrintDialogQuery>;
  isLoading?: boolean;
}> = ({ onDismiss, load, query: queryRef, isLoading }) => {
  const { translate } = useTranslation();
  const { palette } = useTheme();
  const { showSnack } = useGMSnackbar();
  // Stores user input for querying
  const [sparePartNumbers, setSparePartNumbers] = useState<string[]>([]);
  // Stores the current state of the list
  const [mutableSparePartList, setMutableSparePartList] = useState<
    SparePartLabelBulkPrintDialogQuery$data["spareParts"]
  >([]);
  const [chosenPrinterId, setChosenPrinterId] = useState<string | undefined>(undefined);
  const [chosenStorageLocationId, setChosenStorageLocationId] = useState<string | undefined>(undefined);
  const [printQuantity, setPrintQuantity] = useState<number>(1);

  const invalidEntries = useMemo(() => {
    const invalidEntries: number[] = [];
    mutableSparePartList.forEach((fragment, index) => {
      if (fragment?.__typename === "NotFoundError") {
        invalidEntries.push(index + 1);
      }
    });
    return invalidEntries;
  }, [mutableSparePartList]);

  // It is probably fine to only check if mutableSparePartList has any entries, because we check for invalid entries right after
  const isFormCompleted = useMemo(
    () =>
      hasValue(chosenStorageLocationId) &&
      hasValue(chosenPrinterId) &&
      mutableSparePartList.length > 0 &&
      invalidEntries.length < 1,
    [chosenPrinterId, chosenStorageLocationId, invalidEntries.length, mutableSparePartList.length],
  );

  // Cannot be lazy loaded because it hangs re-rendering that prevents the loading spinner form showing inside the component
  const { spareParts } = usePreloadedQuery<SparePartLabelBulkPrintDialogQuery>(
    graphql`
      query SparePartLabelBulkPrintDialogQuery($input: QuerySparePartsInput!) {
        spareParts(input: $input) {
          __typename
          ... on SparePart {
            sparePartId: id
            sparePartNumber
          }
          ... on NotFoundError {
            requestedId
          }
          ...BulkPrintDialogListItem_QuerySparePartsItemResult
        }
      }
    `,
    queryRef,
  );

  const [printMutation, mutationIsLoading] = useMutation<SparePartLabelBulkPrintDialogMutation>(graphql`
    mutation SparePartLabelBulkPrintDialogMutation($input: MutationPrintSparePartLabelBulkInput!) {
      printSparePartLabelBulk(input: $input) {
        ... on MutationPrintSparePartLabelBulkSuccess {
          __typename
        }
      }
    }
  `);

  const onPrintPressed = useCallback(() => {
    if (isFormCompleted) {
      printMutation({
        variables: {
          input: {
            sparePartIds: mutableSparePartList.map((fragment) => fragment?.sparePartId ?? null).filter(hasValue),
            printerId: chosenPrinterId!, // We know that the value is set, otherwise isFormCompleted would be false
            quantity: printQuantity,
            storageLocationId: chosenStorageLocationId!, // We know that the value is set, otherwise isFormCompleted would be false
          },
        },
        onCompleted: () => {
          showSnack({
            message: translate("BULK_PRINT.ON_SUCCESS", "Your print queue has been sent to the selected printer"),
            variant: "success",
          });
          onDismiss();
        },
      });
    }
  }, [
    chosenPrinterId,
    chosenStorageLocationId,
    isFormCompleted,
    mutableSparePartList,
    onDismiss,
    printMutation,
    printQuantity,
    showSnack,
    translate,
  ]);

  const onInputChange = useCallback(
    (data: string[]) => {
      // Use mutableSparePartList, because sparePartNumbers is not updated when an item is removed to prevent refetch
      const currentListContent = mutableSparePartList
        .map((fragment) => fragment?.sparePartNumber?.toString() ?? fragment.requestedId)
        .filter(hasValue);

      // Merge the new entries with the current entries of the list and remove duplicates
      setSparePartNumbers([...new Set([...currentListContent, ...data])]);
    },
    [mutableSparePartList],
  );

  // Remove items from mapped list to avoid unnecessary queries, but update parent state
  const onItemRemoved = useCallback((sparePartNumber: string) => {
    setMutableSparePartList((currentState) =>
      currentState.filter(
        (item) => item.sparePartNumber?.toString() !== sparePartNumber && item.requestedId !== sparePartNumber,
      ),
    );
  }, []);

  // Memoize the rendered items to avoid re-rendering them on every input change. Improves performance when the list is long.
  const renderedListItems = useMemo(
    () =>
      mutableSparePartList.map((fragment, index) =>
        fragment ? (
          <BulkPrintDialogListItem
            key={fragment.sparePartId ?? fragment?.requestedId}
            lineNumber={index + 1}
            onRemove={onItemRemoved}
            sparePart={fragment}
          />
        ) : null,
      ),
    [mutableSparePartList, onItemRemoved],
  );

  useEffect(() => {
    setMutableSparePartList(spareParts);
  }, [spareParts, sparePartNumbers]);

  // Load more data when the user has added new spare part numbers
  useEffect(() => {
    load(sparePartNumbers);
  }, [sparePartNumbers, load]);

  return (
    <BulkPrintDialogStructure
      title={translate("BULK_PRINT.SPARE_PART_DIALOG.TITLE", "Material Label printing")}
      description={translate(
        "BULK_PRINT.SPARE_PART_DIALOG.DESCRIPTION",
        "To create a bulk label list for printing, enter one material number at a time in the text field below or paste the list. Once you have filled in all fields, click the “Print” button.",
      )}
      content={
        <Grid2 container size="grow" direction="column" spacing={1}>
          <Grid2 container direction="column" spacing={2}>
            <Grid2>
              <Typography variant="body1" style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {translate("BULK_PRINT.PRINT_QUEUE", "Print Queue ({{count}})", { count: mutableSparePartList.length })}
              </Typography>
            </Grid2>
            <Grid2>
              <BulkPrintDialogList
                onInput={onInputChange}
                isLoading={isLoading}
                sx={invalidEntries.length > 0 ? { border: `2px solid ${palette.error.main}` } : {}}
                inputPlaceholder={translate(
                  "BULK_PRINT.SPARE_PART_DIALOG.INPUT_PLACEHOLDER",
                  "Write or paste Material IDs here",
                )}
              >
                <>{renderedListItems}</>
              </BulkPrintDialogList>
            </Grid2>
          </Grid2>
          {/* Error message */}
          {invalidEntries.length > 0 ? (
            <Grid2>
              <Typography variant="caption" color={palette.error.main}>
                {translate(
                  "BULK_PRINT.SPARE_PART_DIALOG.INVALID_IDS_ERROR",
                  "One or more material IDs on line(s) [{{ids}}] are invalid. Please remove them from the queue and check it again.",
                  { ids: invalidEntries.join(", ") },
                )}
              </Typography>
            </Grid2>
          ) : null}
        </Grid2>
      }
      printSettings={
        <Grid2 container direction="column">
          <SparePartLabelBulkPrintMroAndStorageLocationInputs.Suspense
            onStorageLocationChange={setChosenStorageLocationId}
          />

          {/* Print settings */}
          <Grid2 container direction="row" spacing={2}>
            {/* Printer name input */}
            <Grid2 size={{ xs: 6 }}>
              <Grid2>
                <Typography variant="body1">{translate("BULK_PRINT.PRINTER_INPUT.TITLE", "Printer")}</Typography>
              </Grid2>
              <Grid2>
                <PrinterAutocomplete.Suspense
                  onPrinterSelected={setChosenPrinterId}
                  sx={{ backgroundColor: palette.background.paper }}
                />
              </Grid2>
            </Grid2>
            {/* Amount input */}
            <Grid2 size={{ xs: 6 }}>
              <Grid2>
                <Typography variant="body1">
                  {translate("BULK_PRINT.AMOUNT_INPUT.TITLE", "Amount of Copies")}
                </Typography>
              </Grid2>
              <Grid2>
                <TextField
                  sx={{ backgroundColor: palette.background.paper }}
                  fullWidth
                  type="number"
                  value={printQuantity}
                  onChange={(e) => setPrintQuantity(parseInt(e.target.value, 10))}
                  slotProps={{ htmlInput: { min: 1 } }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      }
      actions={
        <>
          <Button color="primary" sx={{ mr: 4 }} onClick={onDismiss}>
            {translate("MISC.CANCEL", "Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isFormCompleted}
            onClick={onPrintPressed}
            loading={mutationIsLoading}
          >
            {translate("EQUIPMENT_HEADER.FAB.PRINT_DIALOG.PRINT_BUTTON", "Print")}
          </Button>
        </>
      }
    />
  );
};
