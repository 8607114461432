/**
 * @generated SignedSource<<524a397a144a9f2c65e8157759aed6ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartLabelBulkPrintMroAndStorageLocationInputsQuery$variables = Record<PropertyKey, never>;
export type SparePartLabelBulkPrintMroAndStorageLocationInputsQuery$data = {
  readonly plants: ReadonlyArray<{
    readonly mroPlants: ReadonlyArray<{
      readonly id: string;
      readonly storageLocations: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"SparePartStorageLocationAutocomplete_StorageLocation">;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"MroPlantAutocomplete_MroPlant">;
    }> | null | undefined;
  }> | null | undefined;
  readonly viewer: {
    readonly me: {
      readonly process: {
        readonly plant: {
          readonly mroPlants: ReadonlyArray<{
            readonly id: string;
          }> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  };
};
export type SparePartLabelBulkPrintMroAndStorageLocationInputsQuery = {
  response: SparePartLabelBulkPrintMroAndStorageLocationInputsQuery$data;
  variables: SparePartLabelBulkPrintMroAndStorageLocationInputsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "MroPlant",
  "kind": "LinkedField",
  "name": "mroPlants",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SparePartLabelBulkPrintMroAndStorageLocationInputsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Plant",
        "kind": "LinkedField",
        "name": "plants",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MroPlant",
            "kind": "LinkedField",
            "name": "mroPlants",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MroPlantAutocomplete_MroPlant"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StorageLocation",
                "kind": "LinkedField",
                "name": "storageLocations",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SparePartStorageLocationAutocomplete_StorageLocation"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employee",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Process",
                "kind": "LinkedField",
                "name": "process",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plant",
                    "kind": "LinkedField",
                    "name": "plant",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SparePartLabelBulkPrintMroAndStorageLocationInputsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Plant",
        "kind": "LinkedField",
        "name": "plants",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MroPlant",
            "kind": "LinkedField",
            "name": "mroPlants",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mroNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StorageLocation",
                "kind": "LinkedField",
                "name": "storageLocations",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employee",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Process",
                "kind": "LinkedField",
                "name": "process",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plant",
                    "kind": "LinkedField",
                    "name": "plant",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15ac4071133fbd7a01fac3d18ca843e1",
    "id": null,
    "metadata": {},
    "name": "SparePartLabelBulkPrintMroAndStorageLocationInputsQuery",
    "operationKind": "query",
    "text": "query SparePartLabelBulkPrintMroAndStorageLocationInputsQuery {\n  plants {\n    mroPlants {\n      id\n      ...MroPlantAutocomplete_MroPlant\n      storageLocations {\n        ...SparePartStorageLocationAutocomplete_StorageLocation\n        id\n      }\n    }\n    id\n  }\n  viewer {\n    me {\n      process {\n        plant {\n          mroPlants {\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment MroPlantAutocomplete_MroPlant on MroPlant {\n  id\n  description\n  mroNumber\n}\n\nfragment SparePartStorageLocationAutocomplete_StorageLocation on StorageLocation {\n  id\n  description\n}\n"
  }
};
})();

(node as any).hash = "62e75f5404b39a38e493eebaa6de6e9d";

export default node;
