/**
 * @generated SignedSource<<8c0bc46cb24daec5fb5d77c9125969f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkPrintDialogListItem_QueryEquipmentListItemResult$data = {
  readonly __typename: "NotFoundError";
  readonly requestedId: string;
  readonly " $fragmentType": "BulkPrintDialogListItem_QueryEquipmentListItemResult";
} | {
  readonly __typename: "QueryEquipmentListItemSuccess";
  readonly data: {
    readonly description: string;
    readonly equipmentNumber: number;
  };
  readonly " $fragmentType": "BulkPrintDialogListItem_QueryEquipmentListItemResult";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BulkPrintDialogListItem_QueryEquipmentListItemResult";
};
export type BulkPrintDialogListItem_QueryEquipmentListItemResult$key = {
  readonly " $data"?: BulkPrintDialogListItem_QueryEquipmentListItemResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkPrintDialogListItem_QueryEquipmentListItemResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BulkPrintDialogListItem_QueryEquipmentListItemResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestedId",
          "storageKey": null
        }
      ],
      "type": "NotFoundError",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "equipmentNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "QueryEquipmentListItemSuccess",
      "abstractKey": null
    }
  ],
  "type": "QueryEquipmentListItemResult",
  "abstractKey": "__isQueryEquipmentListItemResult"
};

(node as any).hash = "3fc93b9b0bb31a357f6843a88b9ffcad";

export default node;
