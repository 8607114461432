/* eslint-disable @typescript-eslint/no-explicit-any */
import { Operation, ServerError, ServerParseError } from "@apollo/client";
import { FormattedExecutionResult, GraphQLFormattedError, print } from "graphql";

import { getCrashReporter } from "../utility/crash-reporter";
import { hasValue } from "../utility/hasValue";

const ERROR_CODES_TO_NOT_REPORT = [408, 423, 500, 503];
const HTTP_ERROR_PREFIX = "Request failed with status code ";

export const TICKET_LIST_INDEX_REPLACEMENT_STRING = "$index";

export const stringIsPositiveInteger = (theString: string): boolean => {
  const asNumber = Number.parseFloat(theString);

  return (
    !Number.isNaN(asNumber) &&
    Number.isInteger(asNumber) &&
    asNumber >= 0 &&
    !theString.includes(".") &&
    !theString.includes(",")
  );
};

export const getPathWithoutListIndexValue = (path: string[]): string[] => {
  return path.map((val) => {
    if (stringIsPositiveInteger(val)) {
      return TICKET_LIST_INDEX_REPLACEMENT_STRING;
    }
    return val;
  });
};

export const reportGraphQLError = (operation: Operation, err: GraphQLFormattedError): void => {
  const { operationName, query, variables } = operation;
  const errorCode = getErrorCodeFromGQLError(err);

  const { message, path } = err;
  const context: { headers?: Record<string, string> } = operation.getContext();

  const transactionId: string | undefined = hasValue(context.headers) ? context.headers["x-transaction-id"] : undefined;

  const pathComponents = path?.map((val) => val.toString()) ?? [];
  const pathComponentsWithoutListIndex = getPathWithoutListIndexValue(pathComponents);

  const fingerprints = [operationName, ...pathComponentsWithoutListIndex];

  if (errorCode) {
    fingerprints.push(errorCode.toString());
  }

  if (!isErrorCodeIgnored(errorCode)) {
    getCrashReporter().captureException({
      exception: new Error(`Operation ${operationName} failed with GraphQL errors ${err.message}`),
      context: {
        extra: JSON.stringify({
          operationName,
          message,
          path,
          errorCode,
          transactionId,
          query: print(query),
          variables: redactVariablesForSentry(variables),
        }),
      },
      fingerprint: fingerprints,
      tags: {
        operation: operationName,
      },
    });
  }
};

export const reportNetworkError = (
  operation: Operation,
  networkError: Error | ServerError | ServerParseError | undefined,
): void => {
  const { operationName, query, variables } = operation;

  if (
    networkError?.message.includes("Timeout error") ||
    networkError?.message.includes("Request failed with status code 408") ||
    networkError?.message.includes("Network request failed")
  ) {
    // This is too expensive to report in Sentry, and not something we can do anything about.
    // reportTimeoutError(operation, reporter, plantId);
  } else if (hasValue(networkError)) {
    const { message, name } = networkError;

    const httpCode = getHttpCodeFromError(networkError);

    if (hasValue(httpCode) && isErrorCodeIgnored(httpCode)) {
      return;
    }

    const fingerprints = [operationName, name];

    if (hasValue(httpCode)) {
      fingerprints.push(httpCode.toString());
    }

    getCrashReporter().captureException({
      exception: new Error(`Operation ${operationName} failed with Network Error ${networkError.message}`),
      context: {
        extra: JSON.stringify({
          operationName,
          message,
          query: print(query),
          variables: redactVariablesForSentry(variables),
        }),
      },
      fingerprint: fingerprints,
      tags: {
        operation: operationName,
      },
    });
  }
};

export const reportUnknownApolloError = ({
  operation: { operationName, query, variables },
  response,
}: {
  operation: Operation;
  response:
    | FormattedExecutionResult<
        {
          [key: string]: unknown;
        },
        {
          [key: string]: unknown;
        }
      >
    | undefined;
}): void => {
  getCrashReporter().captureException({
    exception: new Error(`Operation ${operationName} failed with unknown error type (non GQL or network)`),
    context: {
      extra: JSON.stringify({
        operationName,
        query: print(query),
        responseErrors: response?.errors,
        variables: redactVariablesForSentry(variables),
      }),
    },
    tags: {
      operation: operationName,
    },
  });
};

/**
 * Method for filtering out keys in an input object for the GraphQL service that
 * could potentially contain information that is not safe to save in our
 * error reporting tool (such as free text fields that could contain sensitive product
 * or process information)
 * @param {any} target  the GraphQL input object
 * @returns {any} the redacted output
 */
export const redactVariablesForSentry = (target: Record<string, any>): any => {
  return Object.entries(target).reduce((redacted: any, [key, value]) => {
    if (typeof value === "object" && value !== null) {
      const newVal = redactVariablesForSentry(value as Record<string, any>);

      return { ...redacted, [key]: newVal };
    }

    if (typeof value === "boolean" || typeof value === "number") {
      return { ...redacted, [key]: value };
    }

    const allowed = keyIsAllowed(key);
    if (allowed) {
      return { ...redacted, [key]: value };
    } else if (typeof value === "string" || typeof value === "number") {
      return { ...redacted, [key]: "redacted" };
    }

    return redacted;
  }, {});
};

export const generateTransactionId = (): string => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
};

const isErrorCodeIgnored = (code?: number) => {
  if (hasValue(code)) {
    return ERROR_CODES_TO_NOT_REPORT.includes(code);
  }

  return false;
};

const getHttpCodeFromError = (err: GraphQLFormattedError) => {
  if (err.message.includes(HTTP_ERROR_PREFIX)) {
    const code = err.message.substring(HTTP_ERROR_PREFIX.length, 3);
    const asNumber = Number.parseInt(code);

    if (Number.isSafeInteger(asNumber) && !Number.isNaN(asNumber)) {
      return asNumber;
    }
  }
};

const getErrorCodeFromGQLError = (err: GraphQLFormattedError): number | undefined => {
  if (err.extensions && "exception" in err.extensions && err.extensions.exception) {
    const { errorCode } = err.extensions.exception as { errorCode?: number };
    if (errorCode) {
      return errorCode;
    }
  }

  return getHttpCodeFromError(err);
};

const keyIsAllowed = (theKey: string) => {
  const whitelistedKeys: string[] = [
    "input",
    "createdat",
    "updatedat",
    "priority",
    "sorting",
    "filter",
    "after",
    "searchterm",
  ];

  return (
    theKey === "id" ||
    theKey.endsWith("Id") ||
    theKey.toLowerCase().includes("date") ||
    whitelistedKeys.includes(theKey.toLowerCase())
  );
};
