/**
 * @generated SignedSource<<5314f3b639cbfbc74f23f6b8ec1be163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MroPlantAutocomplete_MroPlant$data = ReadonlyArray<{
  readonly description: string;
  readonly id: string;
  readonly mroNumber: number;
  readonly " $fragmentType": "MroPlantAutocomplete_MroPlant";
}>;
export type MroPlantAutocomplete_MroPlant$key = ReadonlyArray<{
  readonly " $data"?: MroPlantAutocomplete_MroPlant$data;
  readonly " $fragmentSpreads": FragmentRefs<"MroPlantAutocomplete_MroPlant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MroPlantAutocomplete_MroPlant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mroNumber",
      "storageKey": null
    }
  ],
  "type": "MroPlant",
  "abstractKey": null
};

(node as any).hash = "8759857e3bee1c0d08f216f7d5f9bd39";

export default node;
