export type PossibleEnvironments = "local" | "uat" | "production" | "e2e" | "test";
export type PossibleGraphqlTargets = "local" | "local-relay" | "local-apollo" | "test";

export const getNodeEnvironment = (): PossibleEnvironments => {
  return (process.env.REACT_APP_ENVIRONMENT as PossibleEnvironments) ?? (process.env.NODE_ENV as never);
};

export const getApiBaseUrl = (): string => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (!baseUrl) {
    throw new Error("Base URL is not defined.");
  }

  return baseUrl;
};

export const getRelayGraphqlBaseUrl = (): string => {
  const target = process.env.REACT_APP_GRAPHQL_TARGET as PossibleGraphqlTargets;

  if (target === "local" || target === "local-relay") {
    return "http://localhost:4000/graphql";
  }
  return `${getApiBaseUrl()}/graphql`;
};

export const getApolloGraphqlBaseUrl = (): string => {
  const target = process.env.REACT_APP_GRAPHQL_TARGET as PossibleGraphqlTargets;

  if (target === "local" || target === "local-apollo") {
    return "http://localhost:4001/maintenance/graphql";
  }
  return `${getApiBaseUrl()}/maintenance/graphql`;
};
