/**
 * @generated SignedSource<<c8564f586744a4852cced60241ed4c39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentList_comment$data = ReadonlyArray<{
  readonly description: string;
  readonly timestampWithOffset?: string;
  readonly " $fragmentType": "CommentList_comment";
}>;
export type CommentList_comment$key = ReadonlyArray<{
  readonly " $data"?: CommentList_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentList_comment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeTimestamp"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CommentList_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "condition": "includeTimestamp",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestampWithOffset",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "bb4b421bcc797ff5f4bc16c6a16d7b3f";

export default node;
